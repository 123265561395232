import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import axios from 'axios'
import {LOGGING_BASE_URL, colors} from '../../../constants'
import {createMarkup} from '../../../util/datoCmsDataFactory'
import ShootingStar from '../../../images/app/shooting-star.svg'
import {isIOS} from '../../../util/userAgent'

const text = {
    en: {
        buttonText: `Join the waitlist`,
        failureMessage: `Something went wrong.`,
        failureBody: `There was an error in your request. Please try again or email <a href="mailto: privacyplease@startpage.com">privacyplease@startpage.com</a> to be manually added to the list.`,
        bodyTextAndroid: `Startpage for Android is coming soon.<br> Join the waitlist to be alerted once it's available for download.`,
        bodyTextIOS: `Startpage for iOS is coming soon.<br> Join the waitlist to be alerted once it's available for download.`,
        thanksMessage: `Thanks for joining the waitlist!`,
        emailInput: `Enter email address`,
        submitButton: `Submit`,
    },
    de: {
        buttonText: `Melde dich für die Warteliste an`,
        failureMessage: `Etwas ist schiefgelaufen`,
        failureBody: `Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder schreibe eine E-Mail <a href="mailto: privacyplease@startpage.com">privacyplease@startpage.com</a>, um manuell zu der Liste hinzugefügt zu werden.`,
        bodyTextAndroid: `Startpage ist bald für Android verfügbar.<br> Trag dich auf der Warteliste ein und wir benachrichtigen dich, sobald der Download zur Verfügung steht.`,
        bodyTextIOS: `Startpage ist bald für iOS verfügbar.<br> Trag dich auf der Warteliste ein und wir benachrichtigen dich, sobald der Download zur Verfügung steht.`,
        thanksMessage: `Danke, dass du dich für die Warteliste angemeldet hast`,
        emailInput: `E-Mail-Adresse angeben`,
        submitButton: `Abschicken`,
    },
    nl: {
        buttonText: `Meld je aan voor de wachtlijst`,
        failureMessage: `Er is iets fout gegaan.`,
        failureBody: `Er is een fout opgetreden. Probeer het opnieuw of stuur een e-mail naar <a href="mailto: privacyplease@startpage.com">privacyplease@startpage.com</a> om handmatig aan de lijst te worden toegevoegd.`,
        bodyTextAndroid: `Startpage voor Android is binnenkort beschikbaar.<br> Meld je aan voor de wachtlijst om een melding te ontvangen zodra deze beschikbaar is om te downloaden.`,
        bodyTextIOS: `Startpage voor iOS is binnenkort beschikbaar.<br> Meld je aan voor de wachtlijst om een melding te ontvangen zodra deze beschikbaar is om te downloaden.`,
        thanksMessage: `Bedankt voor het inschrijven op de wachtlijst!`,
        emailInput: `E-mailadres invoeren`,
        submitButton: `Versturen`,
    },
}

const Waitlist = ({os, languageCode = `en`}) => {
    // Constants
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(0) // Value 0 = neither succeeded nor failed; Value 1 = success; Value 2 = failure

    // Functions
    /**
     * NOTE: handleSubmit only works if running thin_proxy locally in parallel with this app
     * or if the app is deployed to the prod environment, app.startpage.com
     */
    const handleSubmit = (userEmail) => async (event) => {
        event.preventDefault()
        setSubmitted(false)
        setSuccess(0)
        if (!userEmail) {
            setSubmitted(false)
            setSuccess(2)
            return
        }
        try {
            setSubmitted(true)
            await axios
                .post(`${LOGGING_BASE_URL}/do/app-newsletter-signup`, {
                    email: userEmail,
                    lang: languageCode,
                    os,
                })
                .then(() => {
                    setSuccess(1)
                })
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error', error)
            setSuccess(2)
        }
    }

    const handleInput = (event) => {
        setEmail(event.target.value)
    }

    const setText = () => {
        if (success === 2) {
            return text[languageCode].failureBody
        }
        if (success === 0 && submitted) {
            return ''
        }
        if (isIOS()) {
            return text[languageCode].bodyTextIOS
        }
        return text[languageCode].bodyTextAndroid
    }

    useEffect(() => {
        if (window.location.href.match('#waitlist')) {
            document.getElementById('waitlist-section').scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
            document.getElementById('waitlist-form').focus()
        }
    }, [])

    // Render
    return (
        <div
            id="waitlist-section"
            css={css`
                width: 100%;
                position: relative;
            `}
        >
            <div
                className="background"
                css={css`
                    background: linear-gradient(180deg, #17203b 0%, #6677fb 100%);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 0;
                `}
            />
            <div
                css={css`
                    z-index: 4;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 4rem 24px;
                    h2,
                    p {
                        color: ${colors.whisperLight};
                    }
                `}
            >
                <div
                    className="star-container"
                    css={css`
                        position: absolute;
                        top: 30px;
                        left: 30px;
                    `}
                >
                    <ShootingStar />
                </div>
                <h2
                    css={css`
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: 800;
                        max-width: 256px;
                    `}
                >
                    {(success === 1 || success === 0) && text[languageCode].buttonText}
                    {success === 2 && text[languageCode].failureMessage}
                </h2>
                <p
                    css={css`
                        font-size: 16px;
                        margin: 18px 0 34px;
                        line-height: 24px;
                        max-width: 314px;
                        text-align: center;
                        a {
                            color: ${colors.teal};
                        }
                    `}
                    dangerouslySetInnerHTML={createMarkup(setText())}
                />
                {submitted ? (
                    <>
                        {success === 0 && (
                            <img
                                src="https://www.startpage.com/sp/cdn/images/waitlist-loading-icon.svg"
                                alt="loading icon"
                                css={css`
                                    @keyframes spinner {
                                        0% {
                                            transform: translate3d(-50%, -50%, 0) rotate(0deg);
                                        }
                                        100% {
                                            transform: translate3d(-50%, -50%, 0) rotate(360deg);
                                        }
                                    }
                                    animation: 1s linear infinite spinner;
                                `}
                            />
                        )}
                        <p
                            css={css`
                                font-size: 16px;
                                line-height: 24px;
                                margin: 16px 0 0;
                                color: #5b627a;
                            `}
                        >
                            {success === 1 && text[languageCode].thanksMessage}
                        </p>
                    </>
                ) : (
                    <form onSubmit={handleSubmit(email)}>
                        <input
                            id="waitlist-form"
                            type="email"
                            css={css`
                                width: 100%;
                                height: 50px;
                                background-color: #ffffff;
                                border: 1px solid #dee0f7;
                                box-shadow: 0px 20px 26px rgba(0, 0, 0, 0.1);
                                border-radius: 10px;
                                padding: 0 27px;
                                margin-right: 24px;
                                font-size: 16px;
                                line-height: 24px;
                                &:invalid {
                                    border: 2px solid #e23d3d;
                                }
                            `}
                            placeholder={text[languageCode].emailInput}
                            onChange={handleInput}
                        />
                        <input
                            type="submit"
                            css={css`
                                width: 236px;
                                border-radius: 10px;
                                background: ${colors.teal};
                                box-shadow: 0px 20px 26px rgba(0, 0, 0, 0.1);
                                padding: 10px 16px;
                                border: none;
                                font-size: 18px;
                                color: ${colors.black};
                                font-weight: 800;
                                margin-top: 24px;
                                cursor: pointer;
                                &:hover {
                                    background-color: #51ffee;
                                }
                            `}
                            value={text[languageCode].submitButton}
                        />
                    </form>
                )}
            </div>
        </div>
    )
}

export default Waitlist
